.SortingArrow{
    color:white !important;
    font-size: 15px;
}
.SortingArrow:hover{
    color: lightgrey !important;
    font-size: 15px;
}
.SortingArrowEnabled{
    color: grey !important;
    font-size: 15px !important;
}