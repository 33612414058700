.hours-management-main-heading {
  background-color: var(--tin-blue);
  color: white;
  height: 100px;
}

.hours-management-main {
  height: 100vh;
  width: 100%;
}

.hours-management-container {
  overflow: auto;
}

.hours-management-process-button {
  background-color: white !important;
  border-color: var(--tin-cyan) !important;
  border: solid 1px !important;
  border-radius: 14px !important;
  width: 92px !important;
  min-height: 22px !important;
  height: 22px !important;
  padding: 0px !important;
  font-size: 10px !important;
  margin: 30px 20px !important;
}

.hours-management-process-button-enabled {
  color: var(--tin-cyan) !important;
}

.hours-management-upload-text {
  color: var(--tin-cyan) !important;
  padding: 5px;
  margin: 10px 20px !important;
  font-size: 20px !important;
}

.hours-management-upload-complete-text {
  color: var(--tin-cyan) !important;
  padding: 5px;
  margin: 10px 20px !important;
  font-size: 20px !important;
}

.hours-management-upload-button {
  color: var(--tin-cyan) !important;
  background-color: white !important;
  border-color: var(--tin-cyan) !important;
  border: solid 1px !important;
  border-radius: 14px !important;
  width: 92px !important;
  min-height: 22px !important;
  height: 22px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px !important;
}

.hours-management-file-dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  border: dashed 1px;
  border-color: var(--tin-cyan);
  min-height: 200px;
}

.hours-management-invoice-icon {
  margin: 10px;
  height: auto;
  width: 55px;
}

.hours-management-file-drop-text {
  margin-bottom: 10px !important;
  color: var(--tin-cyan) !important;
}

.hours-management-file-checkbox {
  margin: 10px 20px !important;
}

.hours-management-listview {
  flex: 2;
  overflow-y: scroll;
  box-shadow: var(--tin-grey) 4px 1px 20px -5px;
}

.hours-detail-listview-container {
  display: flex;
  flex-direction: row;
  height: 75vh;
}

.hours-management-details-container {
  flex: 6;
}

.hours-management-employee-detail-label {
  display: flex;
  align-items: center;
  justify-content: left;
  flex: 3;
  font-size: 12px;
}

.hours-management-employee-detail-value {
  display: flex;
  align-items: center;
  justify-content: left;
  flex: 2;
  font-size: 11px;
  padding: 5px;
}

.hours-management-generate-email {
  display: flex;
  align-items: center;
  justify-content: left;
  flex: 1;
}

.hours-management-employee-detail-label-all-view {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  padding: 10px;
}

.hours-management-generate-email-all-view {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px;
}

.hours-management-red {
  color: red !important;
}

.hours-management-blue {
  color: var(--tin-blue) !important;
}

.hours-management-green {
  color: var(--tin-green) !important;
}

.hours-management-cyan {
  color: var(--tin-cyan) !important;
}

.hide-content{
  display: none;
}

.hours-management-badge-red {
  color: white !important;
  background-color: red !important;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.5px;
}

.hours-management-badge-blue {
  color: white !important;
  background-color: var(--tin-blue) !important;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.5px;
}

.hours-management-badge-cyan {
  color: white !important;
  background-color: var(--tin-cyan) !important;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.5px;
}

.hours-management-badge {
  margin-right: 5px;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.5px;
}

.hours-management-details-main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
}

.hours-management-details-main table {
  margin-top: 10px;
}

.hours-management-details-main td {
  border: none !important;
}

.hours-management-details-main th {
  border: none !important;
}

.hours-management-details-main tbody {
  display: block;
  overflow: auto;
  width: 100%;
  height: 58vh;
}

.hours-management-details-main thead {
  display: block;
  overflow: auto;
  width: 100%;
}

.hours-management-details-main tr {
  display: flex;
  flex-direction: row;
}

.hours-management-details-main th {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
}

.hours-management-details-main td {
  flex: 1;
}

.hours-management-totalhours-input-label {
  margin: 10px 20px;
}

.hours-management-header-trigger-invoicing {
  background-color: var(--tin-blue);
  color: white;
  border: solid 1px white;
  border-radius: 10px;
  height: 22px;
  width: auto;
  cursor: pointer;
  margin: 0px 10px;
}

.hours-management-header-finalize-invoicing {
  background-color: white;
  color: var(--tin-blue);
  border: solid 1px white;
  border-radius: 10px;
  height: 22px;
  width: auto;
  margin: 0px 10px;
  cursor: pointer;
}

.hours-management-projects-main {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
}

.hours-management-projects-main td {
  border: none !important;
}

.hours-management-projects-main th {
  border: none !important;
  font-size: 16px;
  font-weight: bold;
}

.hours-management-projects-main tbody {
  display: block;
  overflow: auto;
  width: 100%;
  height: 58vh;
}

.hours-management-projects-main thead {
  display: block;
  overflow: auto;
  width: 100%;
}

.hours-management-projects-main tr {
  display: flex;
  flex-direction: row;
}

.hours-management-projects-main th:nth-child(odd) {
  flex: 1;
}

.hours-management-projects-main th:nth-child(even) {
  flex: 2;
}

.hours-management-projects-main td:nth-child(odd) {
  flex: 1;
}

.hours-management-projects-main td:nth-child(even) {
  flex: 2;
}

.hours-management-project-invoice-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
}

.hours-management-project-invoice-main td {
  border: none !important;
}

.hours-management-project-invoice-main th {
  border: none !important;
}

.hours-management-project-invoice-main table {
  margin: 0px;
}

.hours-management-project-invoice-main tbody {
  display: block;
  overflow: auto;
  width: 100%;
  height: 52vh;
}

.hours-management-project-invoice-main thead {
  display: block;
  overflow: auto;
  width: 100%;
}

.hours-management-project-invoice-main tr {
  display: flex;
  flex-direction: row;
}

.hours-management-project-invoice-main th {
  flex: 1;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.hours-management-project-invoice-main td {
  flex: 1;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-link {
  color: white !important;
  text-decoration: none !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.hours-management-clickable-tablerow:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
}

.hours-management-projects-main td {
  display: flex;
  align-items: center;
}

.hours-management-projects-main tr {
  display: flex;
  align-items: center;
}

.hours-management-project-detail-label {
  padding: 5px;
  font-size: 16px;
}

.hours-management-project-detail-value {
  font-size: 14px;
  color: var(--tin-blue);
  padding: 5px;
  margin: 0px
}

.hours-management-project-detail-status-value {
  font-size: 14px;
  padding: 5px;
  margin: 0px;
}

.basic-invoice-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: auto;
  height: auto;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 30px 10px;
}

.hours-management-header-save-invoicing {
  background-color: var(--tin-blue);
  color: white;
  border: solid 1px white;
}

.hours-management-header-save-invoicing:disabled {
  background-color: var(--tin-grey);
  color: white;
  border: solid 1px white;
}

.hours-management-header-save-invoicing-disabled {
  background-color: var(--tin-grey);
  color: white;
  border: solid 1px white;
}

.hours-management-header-edit-invoicing {
  background-color: white;
  color: var(--tin-blue);
  border: solid 1px var(--tin-blue);
}

.hours-management-header-edit-invoicing-disabled {
  background-color: white;
  color: var(--tin-grey);
  border: solid 1px var(--tin-grey);
}

.streamlinedview-main {
  display: flex;
  height: calc(100vh - 128px);
}

.hours-management-button:hover {
  cursor: pointer;
}