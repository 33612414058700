.login-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 100vh;
  padding: 0px;
  margin: 0px;
}

.logo {
  height: auto;
  width: 80%;
  max-width: 100%;
}

.logo-title {
  background-color: var(--tin-blue);
  background-image: url('../../assets/images/bubbles\ white.png');
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  flex: 3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.login-button-area {
  background-color: white;
  background-image: url('../../assets/images/bubbles\ blue.png');
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  flex: 3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.company-logo {
  background: linear-gradient(to right, var(--tin-blue) 50%, white 50%);
  flex: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}

.company-logo-background {
  display: flex;
  flex: row;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button-main {
  border: none !important;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.23) !important;
  width: 80%;
}

.login-button img {
  height: 20px;
}

.login-button span {
  margin-left: 10px;
  font-size: 16px;
  color: #898989;
}