.DatePicker__customCalendarArrow {
  position: absolute;
  width: 0;
  height: 0;
  margin-top: 10px;
  margin-left: 40px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  z-index: 10;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
}
.DatePicker__customCalendarContainer {
  position: absolute;
  margin-top: 20px;
}
.DatePicker__customCalendarArrow,
.DatePicker__customCalendarContainer {
  transform: translateY(2.5em);
  opacity: 0;
  animation: fadeCalendar 0.3s forwards;
}
.DefaultCustomCalendar {
  width: 20em !important; /* previously was 33em */
}
.DefaultCustomCalendar .Calendar__section.-hiddenNext {
  transform: translateX(100%); /* previously was 90% */
}
.DefaultCustomCalendar .Calendar__section.-hiddenPrevious {
  transform: translateX(-100%); /* previously was -90% */
}
.DefaultCustomCalendar .Calendar__section {
  padding: 0 0.5em; /* previously was 0 3.2em */
}
.DefaultCustomCalendar .Calendar__weekDays {
  padding: 0 0.8em; /* previously was 0 2.6em */
}
.DefaultCustomCalendar .Calendar__monthText {
  font-size: 1em !important;
}
.DefaultCustomCalendar .Calendar__yearText {
  font-size: 1em !important;
}
.DefaultCustomCalendar .Calendar__monthArrowWrapper.-left {
  font-size: 0.8em !important;
}
.DefaultCustomCalendar .Calendar__monthArrowWrapper.-right {
  font-size: 0.8em !important;
}
.DefaultCustomCalendar .Calendar__header {
  padding: 2em 1em !important;
}
.DefaultCustomCalendar .Calendar.-noFocusOutline.-ltr {
  min-height: 0 !important;
}
.DefaultCustomCalendar .Calendar__day.-ltr {
  font-size: 1.25em !important;
  min-height: 2.3em;
  color: black;
}
.DefaultCustomCalendar .Calendar__sectionWrapper {
  min-height: 20.5em !important;
}
.Calendar {
  min-height: 0 !important;
}
.Calendar__monthSelectorItem {
  width: calc(100% / 2) !important;
}
.Calendar__monthSelectorItem:not(:nth-child(-n + 3)) {
  margin-top: 1em !important;
}
.buttonStyle {
  border: #0fbcf9;
  color: white;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  background-color: #64CB2F;
}
