.invoice-main-heading {
  background-color: var(--tin-green);
  color: white;
}

.invoice-main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
}

.all-invoices-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
}

.single-invoice-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
}

.all-invoices-main th {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.all-invoices-main td {
  border: none !important;
}

.all-invoices-main th {
  border: none !important;
}

.all-invoices-main tr {
  display: flex;
  flex-direction: row;
}

.all-invoices-main table {
  margin: 0px;
}

.all-invoices-main tbody {
  display: block;
  overflow: auto;
  width: 100%;
  height: 52vh;
}

.all-invoices-main thead {
  display: block;
  overflow: auto;
  width: 100%;
}

.all-invoices-main td {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
}

.all-invoices-main th:nth-child(7) {
  flex: 3;
}

.all-invoices-main td:nth-child(7) {
  flex: 3;
}

.all-invoices-main tbody tr:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

input[type="radio"]:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #61cc40;
  border-radius: 15px;
  border: 5px solid #dfdfdf;
}
