.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: black;
  text-align: center; 
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -95px;
}
.tooltiptext {
  width: 252px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  transition-delay: 1s;
}

.PopupHeader{
  display: flex;
  justify-content: space-between;
}
.HeaderMain{
  padding: 2px 0px;
}
.ProjectHeading{
  color: white;
  font-size: 15px;
  font-weight: 700;
  margin-left: 11px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-family: 'Times New Roman', Times, serif;
  text-align: left;
}
.EditOptions{
  display: flex;
  margin-right: 10px;
}
.DialogDeleteBtn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 11px;
  font-weight: 900;
  box-shadow: none;
}
.DialogDeleteBtn:hover{
  transform: scale(1.2);
}
.NameTag{
  display:inline-block;
  font-size:10px;
  width:26px;
  height:26px;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background: white;
  vertical-align:middle;
  margin-left: 1em;
  }
  .CalendarDiv{
    background-color: white;
    display: flex;
    padding: 0 12px;
  }
  .DateDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: small;
    margin-top: 2px;
    margin-left: 5px;
    justify-content: flex-start;
    width: max-content;
    padding: 13px 0;
    gap: 2px;
  }
  .date{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #363636;
  }
  .hours{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #808080;
    align-self: flex-start;
  }
  .FooterDiv{
    display: flex;
    background-color: #F7F7F7;
    padding: 14px 0;
  }
  .ActivityLog{
    flex-grow: 1;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 8px;
    width: 170px;
    margin-top: 2px;
    text-align: left;
    font-size: small;
  }