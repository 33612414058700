:root {
  --tin-dark-green: #029e5f;
  --tin-blue: #01b9fe;
  --tin-green: #61cc40;
  --tin-grey: #797979;
  --tin-grey-2: #888787;
  --tin-grey-3: #c5c5c5;
  --tin-grey-4: #b6b6b6;
  --tin-grey-hours-border: rgba(213, 213, 213, 0.6);
  --tin-red: #f87978;
  --tin-yellow: #f5bd00;
  --tin-orange: #d43a26;
  --tin-cyan: #189477;
  --tin-light-cyan: rgba(24, 148, 119, 0.04);
  --tin-pink: #d0175d;
  --tin-light-pink: #c992b8;
  --tin-darkblue: #3b3a50;
  --tin-hazelnut: #c9aa92;
  --tin-cancel-pink: #f74483;
  --tin-light-blue: #8ef4fd;
  --tin-lightgrey: #f0f0f0;
  --tin-spacegreen1: #26fb92;
  --tin-spacegreen2: #14da78;
  --tin-spacegreen3: #0dba64;
  --tin-spacegreen4: #04934c;
  --tin-spacegreen5: #04773e;
  --tin-spaceblue1: #00aea8;
  --tin-spaceblue2: #018f8b;
  --tin-spaceblue3: #01706c;
  --tin-spaceblue4: #015a57;
  --tin-spaceblue5: #013b39;
  --tin-dashboardcard1: #0aec9d;
  --tin-dashboardcard2: #61cff9;
  --tin-dashboardcard3: #ff9e00;
  --tin-dashboardcard4: #ff7500;
  --tin-dashboardcard5: #ef472b;
  --tin-admincards1: #46cafd;
  --tin-admincards2: #37afde;
  --tin-admincards3: #319ac3;
  --tin-admincards4: #2a81a2;
  --tin-admincards5: #165770;
  --tin-admincards6: #0f4459;
  --tin-admincards7: #063445;
}

* {
  box-sizing: border-box;
}

.app-container {
  height: 100vh;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(22, 194, 31, 0);
  opacity: 1;
  display: block;
  background: url(./assets/images/calendar-grey.svg) no-repeat;
}
input[type="date"].hours-input-date::-webkit-calendar-picker-indicator {
  margin-right: 5px;
  margin-top: 4px;
}

/* Disable increment/decrement buttons for input type=number in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Disable increment/decrement buttons for input type=number in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}