.dashboard {
  display: flex;
}

.dashboard-container {
  flex: 3;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 64px;
}

.dashboard-drawer {
  flex: 1;
  flex-shrink: 0;
  width: 240px;
  position: relative;
}

.dashboard-app-bar {
  flex: 1;
  z-index: 1201 !important;
  background-color: white !important;
  color: var(--tin-blue) !important;
  box-shadow: none !important;
  border: solid 1px #f1f1f1;
}

.dashboard-main {
  flex-grow: 1;
  height: 100vh;
  background-color: var(--tin-lightgrey);
  background-image: url('../../assets/images/dashboard_fluid.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.dashboard-expandable-home-icon {
  border: solid 0px;
  border-left-width: 3px;
  border-color: var(--tin-blue);
  border-radius: 2px;
  padding-left: 2px;
}

.dashboard-home-icon {
  color: var(--tin-blue);
  height: 30px;
}

.dashboard-expandable-invoice-icon {
  border: solid 0px;
  border-left-width: 3px;
  border-color: var(--tin-green);
  border-radius: 2px;
  padding-left: 2px;
}

.dashboard-invoice-icon {
  color: var(--tin-green);
}

.hours-management-uploaded-container {
  display: flex;
  flex-direction: column;
}